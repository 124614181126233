import React from "react"
import Button from "./Button";

declare global {
    function mjOpenPopin(event: React.MouseEvent<HTMLDivElement, MouseEvent>, element: Element): void;
}

const MailJetBtn = ({}) => {
    return (
        <>
            <data id="mj-w-res-data" className="mj-w-data" data-token="d28f5a293a08f8308674294c1d07301e"
                  data-apikey="2vZk" data-w-id="Fbb" data-lang="en_US" data-base="https://app.mailjet.com"
                  data-width="640" data-height="328" data-statics="statics"/>

            <div className="mj-w-button mj-w-btn" data-token="d28f5a293a08f8308674294c1d07301e"
                 onClick={(event) => mjOpenPopin(event, event.currentTarget)}>
                <div>
                    <div>
                        <div className="mj-w-button-content">
                            <Button text="Subscribe"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MailJetBtn