import React from "react";
import { PagePath } from "../../helpers/PageHelper";
import h_decor_1 from "../../images/decors/h_decor_1.png";
import h_decor_2 from "../../images/decors/h_decor_2.png";
import h_decor_3 from "../../images/decors/h_decor_3.png";
import h_decor_4 from "../../images/decors/h_decor_4.png";
import h_decor_5 from "../../images/decors/h_decor_5.png";
import { L } from "../../l10n/L10n";
import { BuildBurgerFnType, BurgerMenu } from "../BurgerMenu";
import { HeaderScrollBtn } from "../HeaderScrollBtn";
import LoginContainerView, { LoginContainerStyle } from "../LoginContainerView";
import HeaderExternalLink from "../shared/HeaderExternalLink";
import HeaderInternalLink from "../shared/HeaderInternalLink";
import MenuDecor from "../shared/MenuDecor";
import MenuSocialLinks from "../shared/MenuSocialLinks";

const buildBurgerMenuLanding = (closeMenu: BuildBurgerFnType) => (
	<>
		<div>
			<MenuDecor src={h_decor_1}/>
			<HeaderScrollBtn href="#legend" onClick={() => closeMenu()}>{L.page.legend}</HeaderScrollBtn>
		</div>
		<div>
			<MenuDecor src={h_decor_2}/>
			<HeaderScrollBtn href="#about-game" onClick={() => closeMenu()}>About game</HeaderScrollBtn>
		</div>
		<div>
			<MenuDecor src={h_decor_3}/>
			<HeaderScrollBtn href="#cards" onClick={() => closeMenu()}>Cards</HeaderScrollBtn>
		</div>
		<div>
			<MenuDecor src={h_decor_5}/>
			<HeaderInternalLink href={PagePath.MANAENCHANTING}>Mana Enchanting</HeaderInternalLink>
		</div>
		<div>
			<MenuDecor src={h_decor_1}/>
			<HeaderExternalLink href="https://medium.com/@tribalbooks">Blog</HeaderExternalLink>
		</div>
		<div>
			<MenuDecor src={h_decor_4}/>
			<HeaderInternalLink href={PagePath.CARDS}>My collection</HeaderInternalLink>
		</div>

		<LoginContainerView styleType={LoginContainerStyle.MENU}/>

		<MenuSocialLinks/>
	</>
);

const LandingMenu = () => (
	<BurgerMenu buildBurger={buildBurgerMenuLanding}/>
);

export default LandingMenu;