import { observer } from "mobx-react";
import React from "react";
import background_img from "../images/background_img.jpg";
import withVideo from "./VideoFrame.module.scss";

const VideoFrameView = observer((
	{
		children,
		video,
	}: {
		children: React.ReactNode,
		video: string
	},
) => {

	return (
		<div className={withVideo.video_container}>
			<div className={withVideo.gradient_bottom}/>
			<video controls={false} autoPlay loop muted>
				<source src={video} type="video/mp4"/>
				<img src={background_img} alt="tribel books"/>
			</video>
			<div className={withVideo.children}>
				{children}
			</div>
		</div>
	);
});

export default VideoFrameView;
