import React, { useEffect, useState } from "react";
import ModalVideo from "react-modal-video";
import trailer from "./Trailer.module.scss";
import "./Trailer.scss";

const TrailerView = () => {
	const [ready, setReady] = useState(false);
	const [isOpen, setOpen] = useState(false);

	const windowGlobal = typeof window !== 'undefined' && window;

	useEffect(() => {
		setReady(typeof windowGlobal !== 'undefined' && !!windowGlobal)
	}, [windowGlobal])

	return (
		<div className={trailer.container}>
			<div className={trailer.content}>
				<div className={trailer.name}>
					<h1>Lost city</h1>
					<h2>legend confirmed</h2>
				</div>

				{ready && <ModalVideo channel="youtube" autoplay isOpen={isOpen}
				            videoId="XFVkIQjUYIo"
				            onClose={() => setOpen(false)}/>}
				<div className={trailer.btn} onClick={() => setOpen(true)}/>
			</div>
		</div>
	);
};

export default TrailerView;
