import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useApp } from "../../App";
import { PagePath } from "../../helpers/PageHelper";
import books_img from "../../images/books.png";
import ftp from "../../images/char/ftp_icon.png";
import nft from "../../images/char/nft_icon.png";
import pvp from "../../images/char/pvp_icon.png";
import wax from "../../images/char/wax_icon.png";
import leg_img_1 from "../../images/legend_img/leg_img_1.png";
import leg_img_2 from "../../images/legend_img/leg_img_2.png";
import leg_img_3 from "../../images/legend_img/leg_img_3.png";
import leg_img_4 from "../../images/legend_img/leg_img_4.png";
import middleFacebook from "../../images/social/middle_facebook.png";
import middleMedium from "../../images/social/middle_medium.png";
import middleTelegram from "../../images/social/middle_telegram.png";
import middleTwitter from "../../images/social/middle_twitter.png";
import middleYoutube from "../../images/social/middle_youtube.png";
import big_logo from "../../images/tb_logo.png";
import { L } from "../../l10n/L10n";
import BackgroundVideo from "../../videos/background.mp4";
import aboutGame from "../about_game.module.scss";
import books from "../books.module.scss";
import Cards from "../Cards";
import DecoratedTitleView from "../DecoratedTitleView";
import EndSaleView from "../EndSaleView";
import Footer from "../footer";
import footer from "../footer.module.scss";
import TrailerView from "../index/TrailerView";
import MailJetBtn from "../MailJetBtn";
import metaFrame from "../mFrame.module.scss";
import PackTabsView from "../PackTabsView";
import packTabsView from "../PackTabsView.module.scss";
import RarityTabsView from "../RarityTabsView";
import rarityTabsStyles from "../RarityTabsView.module.scss";
import SEO from "../seo";
import GameLink from "../shared/GameLink";
import LayoutBase from "../shared/LayoutBase";
import social from "../social.module.scss";
import { EStartSaleType } from "../StartSaleView";
import VideoFrame from "../VideoFrame";
import LandingHeader from "./LandingHeader";
import LandingMenu from "./LandingMenu";
import Styles from "./LandingPage.module.scss";

const LandingPage = observer(() => {
	const app = useApp();

	useEffect(() => {
		if (app.store.logined)
			app.worldService.updateAssets().catch();
	}, [app.store.logined]);

	return (
		<LayoutBase
			header={<LandingHeader/>}
			menu={<LandingMenu/>}
		>
			<SEO title="Tribal Books"
			     description="Tribal Books is a free-to-play online digital collectible nft-card game"/>
			<VideoFrame video={BackgroundVideo}>
				<div className={Styles.content_with_logo}>
					<img className={Styles.big_logo} src={big_logo} alt="test image"/>
					<GameLink href={PagePath.MANAENCHANTING}>{L.manaEnchanting.enchant}</GameLink>
				</div>
			</VideoFrame>
			<div id="legend" className={Styles.back_wall}>
				<div className={Styles.gradient}/>
				<div className={metaFrame.main_container}>
					<div className={Styles.legend_container}>
						<div className={Styles.begin_legend}>
							<h2>A long long time ago</h2>
							<p>Great civilizations settled the world.</p>
						</div>
						<img className={Styles.leg_img_1} src={leg_img_1} alt="great civilizations image"/>
						<p>
							Each of them had its own heart-city, where they stored their treasures including their
							Tribal Books. Not only did these Tribes’ books contain the wisdom of the people, they also
							held within them magical forces.
						</p>
						<div className={Styles.leg_desc}>
							<p>
								Then a dark demon who desired the Tribal Books set a trap, and lured in the mages
								carrying the Tribal Books of their peoples.
							</p>
							<img className={Styles.leg_img_2} src={leg_img_2} alt="great civilizations image"/>
						</div>
						<div className={Styles.leg_desc_type_2}>
							<img className={Styles.leg_img_3} src={leg_img_3} alt="great civilizations image"/>
							<p className={Styles.text_legend}>At that very moment, the heart-cities of all great
								civilizations were cursed and disappeared from the face of the Earth. Without their
								Tribal Books, the great civilizations were lost and became simple tribes.</p>
						</div>
						<p>
							But according to the prophecy, one day a hero will be born who'll find all the Tribal Books
							and collect the lost forces. Then the enchanted heart-cities will be restored, and those
							great peoples of the past shall return to their former glory.
						</p>
						<img className={Styles.leg_img_4} src={leg_img_4} alt="great civilizations image"/>
					</div>
				</div>
			</div>
			<div id="about-game" className={aboutGame.back_about}>
				<div className={Styles.gradient}/>
				<div className={metaFrame.main_container}>
					<DecoratedTitleView text="About Game"/>
					<div className={aboutGame.about_game_content}>
						<div className={aboutGame.desc}>
							<p>
								Tribal Books is a free-to-play online digital collectible step-by-step NFT-card
								game. In each round, only two players take part. They compete with each other
								using the cards and magical forces from the Tribal Books. Only one will become a
								winner.</p>
							<p>Collect NFT cards. Use them to beat the foe.
								Upgrade your Tribal Book to open powerful magic forces. Become the hero who will
								restore the greatness of the enchanted heart-cities and get their treasures!</p>
						</div>
						<div className={aboutGame.img_container}>
							<div className={aboutGame.box}>
								<img src={ftp} alt="free to play icon "/>
								<h3>
									Free to play
								</h3>
							</div>
							<div className={aboutGame.box}>
								<img src={pvp} alt="pvp icon "/>
								<h3>
									PvP Battles
								</h3>
							</div>
							<div className={aboutGame.box}>
								<img src={nft} alt="nft icon "/>
								<h3>
									NFT trade assets
								</h3>
							</div>
							<div className={aboutGame.box}>
								<img src={wax} alt="nft icon "/>
								<h3>
									Wax blockchain
								</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
			<TrailerView/>
			<div className={metaFrame.main_container} id={"cards"}>
				<DecoratedTitleView text="Cards"/>
				<Cards/>
			</div>
			<div className={Styles.back_wall}>
				<div className={Styles.gradient}/>
				<div className={metaFrame.main_container}>
					<div className={social.follow_container}>
						<h1>Follow Tribal Books </h1>
						<p>Hear even the quietest whispering of ancient spirits telling the precious info.</p>
						<div className={social.follow_social_box}>
							<a href="https://twitter.com/tribalbooks" target="_blank">
								<div className={social.twitter_box}>
									<img src={middleTwitter} alt="twiter"/>
								</div>
								Twitter
							</a>
							<a href="https://t.me/tribalbooks" target="_blank">
								<img src={middleTelegram} alt="telegram"/>
								Telegram
							</a>
							<a href="https://tribalbooks.medium.com/" target="_blank">
								<img src={middleMedium} alt="medium"/>
								Medium
							</a>
							<a href="https://www.youtube.com/channel/UCIxBDLtqaA984DcnrrPrfMw" target="_blank">
								<img src={middleYoutube} alt="medium"/>
								Youtube
							</a>
							<a href="https://www.facebook.com/tribalbooksgame/" target="_blank">
								<img src={middleFacebook} alt="medium"/>
								Facebook
							</a>
						</div>
					</div>
				</div>
			</div>
			<EndSaleView type={EStartSaleType.DEFAULT}/>
			<div className={footer.back}>
				<div className={Styles.gradient}/>
				<div className={footer.subscribe_box}>
					<h2>Get Email Updates From Tribal Books</h2>
					<MailJetBtn/>
				</div>
				<Footer/>
			</div>
		</LayoutBase>
	);
});

export default LandingPage;
