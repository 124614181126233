import React, { ReactNode } from "react";
import Styles from "./HeaderLink.module.scss";

const HeaderExternalLink = (
	{
		children,
		href,
	}: {
		children: ReactNode,
		href: string,
	},
) => (
	<a className={Styles.headerLink} href={href} target="_blank">
		{children}
	</a>
);

export default HeaderExternalLink;